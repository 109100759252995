<template>
  <ModalLayout
    v-model="isOpen"
    class-content="BotTokenDialog"
    no-header
    :persistent="!showCloseButton"
    :max-width="477"
    hide-close-btn
    padding="24px 48px"
  >
    <div style="width: 100%">
      <div
        class="d-flex align-center text-truncate mb-6"
      >
        <UserAvatar
          :user="bot"
          icon="mdi-robot-outline"
          size="lg"
        />
        <div class="text-truncate ml-4">
          <div class="UserDialog__title">
            {{ bot.userLogin }}
          </div>
          <div
            class="text--secondary text-truncate"
            v-text="$t('user.Bot')"
          />
        </div>
      </div>

      <AppTextField
        :value="token"
        :label="$t('user.Token')"
        filled
        readonly
        :messages="$t('user.SaveTokenM')"
        @click="onCopyTokenClick"
      />
    </div>

    <template #footer>
      <v-spacer />

      <v-btn
        v-if="showCloseButton"
        outlined
        color="primary"
        class="mr-5"
        @click="isOpen = false"
      >
        {{ $t('layout.Close') }}
      </v-btn>

      <v-btn
        depressed
        color="primary"
        @click="onCopyTokenClick"
      >
        {{ $t('layout.CopyToken') }}
        <v-icon
          class="ml-2"
          v-text="'mdi-content-copy'"
        />
      </v-btn>
    </template>
  </ModalLayout>
</template>

<script>
import { ModalLayout } from '@hexway/shared-front'
import copyToClipboard from 'copy-to-clipboard'

import UserAvatar from './UserAvatar'

export default {
  name: 'BotTokenDialog',

  components: {
    ModalLayout,
    UserAvatar,
  },

  props: {
    dialogInstance: { type: Object, required: true },
    botId: { type: String, required: true },
    token: { type: String, required: true },
  },

  data() {
    return {
      showCloseButton: false,
    }
  },

  computed: {
    isOpen: {
      get() { return this.dialogInstance.isOpen },
      set(isOpen) { this.dialogInstance.onDialogModelInput(isOpen) },
    },

    bot() { return this.$store.getters['botUser/get'](this.botId) },
  },

  methods: {
    onCopyTokenClick() {
      copyToClipboard(this.token)
      this.$store.commit('$snackbar/setMessage', {
        message: this.$t('layout.TokenCopied'),
        timeout: 3000,
        hideCloseBtn: true,
        prependIcon: 'mdi-check-circle-outline',
        prependIconColor: 'success',
      })
      this.showCloseButton = true
    },
  },
}
</script>
